<template>
  <div class="create-store-applications">
    <v-card elevation="0">
      <v-card-title>{{ $t("__createstoreapplications_title") }}</v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-data-table :headers="tableHeaders" :items="formList">
            <template v-slot:[`item.store_name`]="{ item }">
              <v-btn text block @click="toApplication(item.id)">
                {{ item["store_name"] }}
                <v-spacer />
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { adminAPI } from "@/plugins/service";
import { format } from "date-fns";

export default {
  name: "CreateStoreApplications",
  computed: {
    formList() {
      const list = [];
      for (const formID of this.formIDs) {
        const form = this.forms[formID];
        list.push({
          id: formID,
          store_name: form["store_name"],
          submit_time: this.formatTime(form["submit_time"]),
          reply_time: this.formatTime(form["reply_time"])
        });
      }
      return list;
    }
  },
  data() {
    return {
      formIDs: [],
      forms: {},
      tableHeaders: [
        {
          text: this.$t("__store_name"),
          align: "start",
          value: "store_name"
        },
        {
          text: this.$t("__submit_time"),
          align: "start",
          value: "submit_time"
        },
        {
          text: this.$t("__reply_time"),
          align: "start",
          value: "reply_time"
        }
      ]
    };
  },
  methods: {
    loadCreateStoreApplicationForms() {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.getCreateStoreApplicationForms(
            idToken,
            "",
            "submitted"
          );
        })
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          this.formIDs = result["create_store_application_form_ids"];
          this.forms = result["create_store_application_forms"];
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.back();
        });
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm:ss");
    },
    toApplication(formID) {
      this.$router.push({
        name: "CreateStoreApplication",
        params: { id: formID }
      });
    }
  },
  created: function() {
    this.loadCreateStoreApplicationForms();
  }
};
</script>
